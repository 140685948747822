/*This file handles the GDPR cookie selection portion of the site*/


.cookie-consent {
    background: lighten($primary-color, 50);
    position: fixed;
    top: 90%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -90%);
    font-size: 0.8rem;
    border-radius: 2px;
    border: 1;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);

    .gdpr-error {
        color: $alert-color;
    }

    .cookie-content {
        padding: 20px;
    }

    .title-bar {
        background-color: $primary-color;
        display: flex;
        justify-content: space-between;
    }

    .tooltip {
        position: relative;
        .tooltiptext {
            visibility: hidden;
            width: 350px;
            background-color: $secondary-color;
            color: #fff;
            text-align: center;
            padding: 5px;
            border-radius: 6px;
            font-size: 0.8em;
            /* Position the tooltip text - see examples below! */
            position: absolute;
            bottom: -30%;
            z-index: 1;
            box-shadow: 5px 3px 3px transparentize($color: $black, $amount: .6);
        }
        &:hover .tooltiptext {
            visibility: visible
        }
    }
}

.gdpr-cookies {
    display: flex;
    justify-content: space-around;
}

.gdpr-button {
    display: flex;
    justify-content: flex-end;
    button.button.success {
        color: $white;
        box-shadow: 3px 3px 3px rgba(0,0,0,.1);
    }
}



.cookie-dashboard {
    background: white;
    .gdpr-notification {
        color: $success-color;
        font-weight: bold;
    }
}