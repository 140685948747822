
@import 'settings';
@import '../node_modules/foundation-sites/scss/foundation';
@import 'loader';
@import 'promo';
@import 'gdpr';
@include foundation-global-styles;
@include foundation-xy-grid-classes;
// @include foundation-grid;
//@include foundation-flex-grid;
// @include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
// @include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
@include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar; 
@include foundation-visibility-classes;
@include foundation-float-classes;


$transparent_background: rgba(0,0,0,0);

body {
    background-color: $body-background;
}

nav {
    background: $transparent_background;
}

.top-bar {
    background: $transparent_background;
    ul {
        background: $transparent_background;
        a {
            color: white;
        }
    }
}

.menu {
    background: $transparent_background;
}

.navigation {
    // background: url('../assets/hero.jpg');
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5533ff+0,2989d8+50,207cca+100,7db9e8+100 */
    background: #5533ff; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #5533ff 0%, #2989d8 50%, #207cca 100%, #7db9e8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #5533ff 0%,#2989d8 50%,#207cca 100%,#7db9e8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5533ff 0%,#2989d8 50%,#207cca 100%,#7db9e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5533ff', endColorstr='#7db9e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    background-size: cover;
    background-image:url('/static/assets/ozadje.jpg');
    h1.logo {
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        a {
            color: $white;
        }
    }

    .button.cta {
        background-color: $secondary-color;

        &:hover {
            background-color: hsl(103, 85%, 34%);
        }
    }
    .button.hollow {
        color:white;
        border-color: white;
        margin-left: 5px;
        &:hover {
            background-color: $white;
            color: $primary-color;
            font-weight: bold;
        }
    }

}
.hero {

    margin-top: 2%;
    // margin-bottom: 2%;
    padding: 3% 0;

    h1 {
        color: $white;
    }
    .registration {
        display:flex;
        align-items: center;
    }
    .button.cta {
        background-color: $secondary-color;
        border-radius: 5px;
        font-size: 1.2em;

        &:hover {
            background-color: hsl(103, 85%, 34%);
        }
    }
    .flavour-text {
        color: $white;
    }


}

.hero-lean.hero {
    padding: 0;
    margin: 0;
}

.footer {
    padding:10px;
    padding-top: 20px;
    background: $zfm-dark;
    color: darken($white, 20);
    font-size: .9em;
    a {
        color: $white;
    }
}

.content {
    background: $white;
    padding: 1rem;
    border-radius:3px;
    border: 1px solid #dedede;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-left: 1.5rem;
    h2 {
        border-bottom: 2px solid rgba(0,0,0,.7);
    }

}



.days {
    font-size: .8rem;
}

%date {
    font-size: 0.7rem;
    margin-bottom: 0;
    display: inline;
}


.news-item {
    padding-bottom: 1.5em;
    margin-top:1.5em;
    border-bottom: 3px solid rgba(0,0,0,.05);
    .news-date {
        @extend %date;
    }
    h3.news-title {
        margin-bottom: 0;
        // margin-top: 1em;
        font-size: 1.15rem;

    }
    span {
        font-size: 0.8rem;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
    }
    span.question {
        color: $alert-color;
    }
    .news-category {
        display: inline;
        font-size: 0.8rem;
        // display:inline;
        margin-left: 0;
        li {
            display: inline;
            font-size: .8rem;
        }
    }

    .news-question {
        font-size: 1.1rem;
        // font-style: italic;
        margin-bottom: 1rem;
        font-weight: bold;
        margin-top:1rem;
    }

    p.news-answer {
        font-weight: bold;
    }
}

nav.main-navigation {
    background-color: $secondary-color;

    a {
        color:white;
        transition: .2s;
        &:hover {
            background: darken($warning-color, $amount: 8);
        }
    }
    a.active {
        text-decoration: underline;
    }
}

.box {
    @extend .content;
    h3 {
        border-bottom: 2px solid rgba(0,0,0,.7);
    }

    .box-date {
        @extend %date;
    }
    ul {
        margin-left: 0;
        list-style-type: none;
        li {
            margin-bottom:.4em;
        }
    }

    .box-description {
        font-size: 0.8rem;
        display:block;
    }


}

div.account_form {
    padding: 1em;
    @extend .box;

    h2 {
        margin-bottom: 0.5em;
    }
    p.error {
        color: $alert-color;
    }
    .button.submit_button {
        background-color: $secondary-color;

        &:hover {
            background-color: hsl(103, 85%, 34%);
        }
    }
}

.gdpr {
    .gdpr_confirm {
        font-weight: bold;
        margin-left: .3em;
    }

    label.gdpr {
        font-size: 0.8em;
        line-height: 1.2;
    }
}

.handbooks {
    position: fixed;
    width: auto;
    height: auto;
    @include breakpoint(medium down) {
        bottom: 0em;
        left: 0em;
        right: 0em;
    }
    @include breakpoint(large) {
        bottom: 1em;
        right: 1em;
    } // background-color: $secondary-color;
    padding-top: 10px;
    h3 {
        color: $white;
    }
    .handbook-card {
        .handbook-title {
            color: $black;
            font-size: 0.8rem;
            text-align: center;
            margin-top: .3rem;
            margin-bottom: .2rem;
            padding-left: .3rem;
        }
        .handbook-image {
            text-align: center;
            margin: 0 auto;
            margin-bottom: .6em;
            display: block; // box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .2);
            max-width: 80px;
        }
    }
    .naslov {
        padding: .2rem .2rem 0rem .2rem;
    }
    .inner {
        background: lighten($handbook-color, 40);
    }
    .handbook_navigation {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        background-color: $handbook-color;
        padding-left: .4rem;
        h3 {
            margin-top: .4rem;
        } // margin-bottom: 1rem;
        box-shadow: 0px 3px 2px 0 rgba(0,
        0,
        0,
        .5);
        cursor: pointer;
    }
    .arrow {
        font-size: 2rem;
        color: $white;
        line-height: .5;
        cursor: pointer;
    }
    .arrow_rotate {
        transform: rotate(180deg);
        transform-origin: bottom;
    }
    @include breakpoint(small only) {
        img.handbook-image {
            display: none;
        }
        .naslov h4 {
            font-size: 0.8rem;
        }
    }
    .choice {
        display: flex;
        justify-content: center;
        margin-top: .2rem;
        font-size: 0.8rem;
        .divider {
            margin: 0 .3rem;
        }
    }
    .handbook-container {
        padding-top: 1rem;
        padding: 1rem;
        padding-bottom: 0;
        transition: padding-top .2s ease;
        height: 100%;
        opacity: 100%;

    }
    .handbook-container_hidden {
        height: 0;
        padding-top: 0;

        opacity: 0%;
    }
}

/* deadline explanations*/

.highlight-g::before {
    content: "";
display: inline-block;
width: 0;
height: 0;
border-top: 8px solid transparent;
border-bottom: 8px solid transparent;
border-left: 12px solid #6c3bdd;
position: relative;
// position: absolute;
top: 3px;
left: 0;
}

.highlight-j::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #167a99;
    position: relative;
    // position: absolute;
    top: 3px;
    left: 0;
}

.highlight-o::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #991616;
    position: relative;
    // position: absolute;
    top: 3px;
    left: 0;
}

.triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: relative;
    // position: absolute;
    top: 3px;
    left: 0;
    margin-right:.3em;
}

.triangle-g {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #6c3bdd;
    position: relative;
    // position: absolute;
    top: 3px;
    left: 0;
    margin-right:.3em;
}
.triangle-j {
    @extend .triangle;
    border-left: 12px solid #167a99;

}
.triangle-o {
 @extend .triangle;
 border-left: 12px solid #991616;
}

.legend {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
}
p.legend {
    margin-bottom: 0;
}

.explanation {
    margin-top: 2em;
}




.deadline-border {
    border-left-width: 8px;
    border-left-style: solid;
    padding-left: 10px;
}

.deadline-border_g {
    @extend .deadline-border;
    border-left-color: #991616;
}

.deadline-border_j {
    @extend .deadline-border;
    border-left-color:  #167a99;
}

.deadline-border_o {
    @extend .deadline-border;
    border-left-color: #6c3bdd;
}



.modal-background {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: rgba(0, 0, 0, .4);
    display: flex;
    align-items: center;
}

.popup_register {
    position: relative;
    background: #9fe0ff;
    // color: $white;
    div {
        display: flex;
        justify-content: center;
    }
    .close {
        position: absolute;

        top:0;
        right: 0;
        padding: 10px;
        font-weight: bold;
        color: $white;
        cursor: pointer;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
    }

}

li.no-bullet {
    list-style-type:none;
    margin-left: -1.2em;
}
div.badge-container {
    display:inline-block;

}
span.badge.small {
    font-size: 0.5em;
    font-weight: bold;
}



.document-forbidden {
    position: relative;
    .tooltiptext {
        visibility: hidden;
        width: 150px;
        background-color: $secondary-color;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        font-size: 0.8em;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        box-shadow: 5px 3px 3px transparentize($color: $black, $amount: .4);
    }
    &:hover .tooltiptext {
        visibility: visible
    }
}


/* handbooks content*/

.box.handbook-content {
    padding-left: 1em;
}

.frame {
    border: 2px solid rgba(0, 0, 0, .1);
    padding: 10px;
    background: transparentize($warning-color, .9);
}

.headingContainer {
    position: sticky;
    top: 20px;
}

.tocContainer {
    position: sticky;
    top: 20px;
} 





.gdpr-inner {
    background: white;
    font-size: .8rem;
    max-height: calc(100vh - 210px);
    overflow-y: auto;

}

.modal-background.gdpr-modal {
    .callout {
        width: 90%;
        margin: 0 auto;
    }
}

