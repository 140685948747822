
.handbooks_promo {

    position: fixed;
    width: auto;
    height: auto;
    bottom: 0em;
    left: 0em;
    right: 0em;

    // background-color: $secondary-color;
    padding-top: 10px;
    h3 {
        color: $white;
    }

    .handbook-card {
        .handbook-title {
            color: $black;
            font-size: 0.8rem;
            text-align: center;
            margin-top: .3rem;
            margin-bottom: .2rem;
            padding-left: .3rem;
        }
        .handbook-image {
            text-align: center;
            margin: 0 auto;
            margin-bottom: .6em;
            display: block;
            // box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .2);
            max-width: 80px;

        }
    }
    .naslov {
        padding: .2rem .2rem 0rem .2rem;
    }
    .inner {
        background: lighten($promo-color, 60);
    }

    .handbook_navigation {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        background-color:$promo-color;
        padding-left: .4rem;
        h3 {
            margin-top: .4rem; 
        }
        // margin-bottom: 1rem;
        box-shadow: 0px 3px 2px 0 rgba(0, 0, 0, .5);
        cursor: pointer;
    }

    .arrow {
        font-size: 2rem;
        color: $white;
        line-height: .5;
        cursor: pointer;
    }
    .arrow_rotate {
        transform: rotate(180deg);
        transform-origin: bottom;
        
    }
    
    @include breakpoint(small only) {
        img.handbook-image {
            display: none;
        }
        .naslov h4 {
            font-size: 0.8rem; 
        }
    }

    .choice {
        display: flex;
        justify-content: center;
        margin-top: .2rem;
        font-size: 0.8rem;

        .divider {
            margin:0 .3rem;
        }

    }

    .handbook-container {
        padding-top: 1rem;
        transition: padding-top .2s ease;
        height: 100%;
    }
    .handbook-container_hidden {
        height: 0;
        padding-top: 0;
    }
}


.close {
    cursor: pointer;
}